import React from 'react';
import './index.css';

function App() {
  const imageUrl = `${process.env.PUBLIC_URL}/logo.png`; // Change this to the image you want to test

  console.log("Image URL:", imageUrl); // Log the URL to verify it's correct

  const handleError = (event) => {
    event.target.src = ''; // Remove the fallback for this test
    event.target.alt = 'Image not found';
  };

  return (
    <div className="min-h-screen flex flex-col bg-candle-background bg-cover bg-center text-white">
      <div className="relative flex-grow">
        <header className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center font-courgette text-white">
          <img
            src={imageUrl}
            alt="Cotton Wick Logo"
            className="mx-auto"
            style={{ maxWidth: '100%', height: 'auto' }}
            onError={handleError}
          />
          <p className="text-7xl mt-8 font-bold font-roboto">COMING SOON!</p>
        </header>
      </div>
      <footer className="bg-gray-800 bg-opacity-75 text-white p-4 text-center w-full">
        <p className="font-roboto">&copy; 2024 Cotton Wick. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;